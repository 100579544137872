import store from '../store'
import { getCurrentGender } from './gender.service'
import { getCurrentCulture } from './culture.service'
import { getCurrentClazz } from './clazz.service'
import { getCurrentCraft } from './craft.service'
import { getCurrentTalents } from './talent.service'
import { getCurrentSpells } from './spell.service'
import { getCurrentImpact } from './impact.service'
import { getCurrentRings, getCurrentAmulets, getCurrentArmor, getCurrentShield, getCurrentMeleeWeapons, getCurrentRangedWeapons, getCurrentItems } from './items.service'
import { getCurrentRunes } from './rune.service'
import { getCurrentEyeColor, getCurrentHairColor, getCurrentSkinColor } from './personality.service'
import { getCurrentLanguages } from './language.service'
function getMalusObjectByObjectNameAndAttributeNames(objectName, attributeNames) {
  const currentMalusObjects = getAllMalusObjectsByName(objectName)
  const malusObject = {}
  attributeNames.forEach(attributeName => (malusObject[attributeName] = 0))
  currentMalusObjects.forEach(currentObject => {
    if (currentObject[objectName] !== undefined) {
      currentObject[objectName].forEach(currentMalusObject => {
        const attributeName = currentMalusObject.name
        const attributeValue = currentMalusObject.value
        if (attributeNames.includes(attributeName)) {
          if (malusObject[attributeName] === undefined) {
            malusObject[attributeName] = parseInt(attributeValue)
          } else {
            malusObject[attributeName] = malusObject[attributeName] + parseInt(attributeValue)
          }
        }
      })
    } else {
      const currentMalusObject = currentObject
      const attributeName = currentMalusObject.name
      const attributeValue = currentMalusObject.value
      if (attributeNames.includes(attributeName)) {
        if (malusObject[attributeName] === undefined) {
          malusObject[attributeName] = parseInt(attributeValue)
        } else {
          malusObject[attributeName] = malusObject[attributeName] + parseInt(attributeValue)
        }
      }
    }
  })
  return malusObject
}
function getAllMalusPointsByName(attributeName) {
  const malusObjects = getAllMalusObjectsByName(attributeName)
  const malusPoints = malusObjects.length > 0 ? malusObjects.map(malusObject => malusObject[attributeName]).reduce((accumulator, currentValue) => accumulator + currentValue) : 0
  return parseInt(malusPoints)
}
function getAllMalusObjectsByName(attributeName) {
  return [
    ...provideMalusObjectsByName([getCurrentGender(), getCurrentCulture(), getCurrentClazz(), getCurrentCraft(), getCurrentHairColor(), getCurrentEyeColor(), getCurrentSkinColor()], attributeName),
    ...provideMalusObjectsByName(getCurrentLanguages(), attributeName),
    ...provideMalusObjectsByName(getCurrentTalents(), attributeName),
    ...provideMalusObjectsByName(getCurrentSpells(), attributeName),
    ...provideMalusObjectsByName(getCurrentRunes(), attributeName),
    ...provideMalusObjectsByName(getCurrentRings(), attributeName),
    ...provideMalusObjectsByName(getCurrentAmulets(), attributeName),
    ...provideMalusObjectsByName(getCurrentArmor(), attributeName),
    ...provideMalusObjectsByName(getCurrentShield(), attributeName),
    ...provideMalusObjectsByName(getCurrentMeleeWeapons(), attributeName),
    ...provideMalusObjectsByName(getCurrentRangedWeapons(), attributeName),
    ...provideMalusObjectsByName(getCurrentItems(), attributeName),
    ...provideMalusObjectsByName(getCurrentImpact(), attributeName)
  ]
}
function provideMalusObjectsByName(objects, attributeName) {
  return objects.length ? objects.filter(object => object !== undefined).flatMap(object => provideMalusObjectByName(object, attributeName)) : []
}
function provideMalusObjectByName(object, attributeName) {
  const malusObject = hasObjectLevelMalus(object) && object.malus[attributeName] !== undefined ? object.malus[attributeName] : undefined
  const malusLevelbasedObjects = hasObjectLevelBasedMalus(object) ? object.malus.levelBased.filter(levelBased => levelBased[attributeName] !== undefined && levelBased.value <= parseInt(store.state.character.personality.level)) : []
  return createResultObject(malusObject, malusLevelbasedObjects, attributeName)
}
function createResultObject(malusObject, malusLevelbasedObjects, attributeName) {
  if (malusObject !== undefined) {
    if (Object.keys(malusObject).length === 0) {
      const wraperObj = {}
      wraperObj[attributeName] = malusObject
      return [wraperObj, ...malusLevelbasedObjects]
    }
    if (Array.isArray(malusObject)) {
      return [...malusObject, ...malusLevelbasedObjects]
    }
    return [malusObject, ...malusLevelbasedObjects]
  }
  return malusLevelbasedObjects
}
function hasObjectLevelMalus(object) {
  return object !== undefined && object.malus !== undefined
}
function hasObjectLevelBasedMalus(object) {
  return hasObjectLevelMalus(object) && object.malus.levelBased !== undefined
}
export { getAllMalusObjectsByName, getAllMalusPointsByName, getMalusObjectByObjectNameAndAttributeNames }
